<template>
  <transition name="slide">
    <div class="sercice">
      <h :isback="true">
        <span class="name">联系客服</span>
      </h>
      <div class="main" v-text="content" />
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
import { conact } from "@/api/user";
export default {
  components: {
    h
  },
  data() {
    return {
      content: ""
    };
  },
  created() {
    this.kefu();
  },
  methods: {
    kefu() {
      const params = {
        id: 1
      };
      conact(params).then(res => {
        if (!res) {
          return;
        }
        this.content = res.object.phone;
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.sercice
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #f0f0f0
  z-index 11
  .main
    margin 15px
</style>
